<template>
  <div id="home">
    <BannerHome ref="refbannerHome" />
    <SubBanner />
    <NewProductsCarousel
      :show-swatches="false"
    />
    <RelatedProductsCarousel
      :show-swatches="false"
    />
    <PreFooterHome />
    <PageGtm />
    <PageTrackingGtm
      v-if="metaTitle"
      :page-title-gtm="metaTitle"
      page-type-gtm="home"
    />
    <Organization />
  </div>
</template>
<script type='module'>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@gemini/vsf-cache';
import BannerHome from '~/components/BannerHome.vue';
import PageGtm from '~/components/Gtm/PageGtm.vue';
import PageTrackingGtm from '~/components/Gtm/PageTrackingGtm.vue';
import Organization from '~/components/StructuredData/Organization.vue';
import { useGetAlternateMatching } from '~/helpers/alternate/getAlternateMatching';
import { useI18n } from '../helpers/hooks/usei18n';

const SubBanner = () => import('~/components/SubBanner.vue');
const RelatedProductsCarousel = () => import('~/components/RelatedProductsCarousel.vue');
const NewProductsCarousel = () => import('~/components/NewProductsCarousel.vue');
const PreFooterHome = () => import('~/components/PreFooterHome.vue');

export default defineComponent({
  name: 'HomePage',
  components: {
    Organization,
    BannerHome,
    SubBanner,
    NewProductsCarousel,
    RelatedProductsCarousel,
    PreFooterHome,
    PageGtm,
    PageTrackingGtm,
  },
  setup() {
    const { addTags } = useCache();
    addTags([{ prefix: CacheTagPrefix.View, value: 'homepage' }]);
    const { locale } = useI18n();
    const metaTitle = locale === 'en' ? 'Made in Italy clothing - New Online collection' : 'Lazzari, abbigliamento Made in Italy – Nuova collezione Online';
    const metaDescription = locale === 'en' ? 'On lazzarionline.com you will find women\'s clothing and accessories made entirely in Italy. From the fabrics used to the actual crafting process Lazzari clothes are made “like they used to be”.' : 'Su lazzarionline.com trovi capi e accessori da donna realizzati interamente in Italia. Dai tessuti utilizzati al confezionamento, gli abiti Lazzari sono realizzati “come una volta”. ';
    const { getAlternatePageArray } = useGetAlternateMatching();
    const refDataSeoAlternate = ref([]);
    refDataSeoAlternate.value = getAlternatePageArray();
    return {
      metaTitle,
      metaDescription,
      refDataSeoAlternate,
    };
  },
  head() {
    const link = [];

    if (this?.refDataSeoAlternate) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      link.push(...this.refDataSeoAlternate);
    }
    return {
      title: this?.metaTitle,
      meta: [
        { hid: 'robots', name: 'robots', content: 'index, follow' },
        { hid: 'og:description', property: 'og:description', content: this?.metaDescription },
        { hid: 'description', name: 'description', content: this?.metaDescription },
        { hid: 'og:title', property: 'og:title', content: this?.metaTitle },
      ],
      link,
    };
  },
});
</script>

<style lang='scss' scoped>
#home {
  background: #f5f2ee;
  box-sizing: border-box;
  padding: 0;
  overflow: hidden;

  @include for-desktop {
    max-width: 100%;
    margin: 0 auto;
  }
}

.story_top_container {
  padding: 0 84px 80px;

  @media (max-width: 768px) {
    padding: 0 24px 23px;
  }
}
</style>
